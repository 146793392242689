<template>
  <div class="d-flex header" :class="{ userLoggedIn: user && user.active }">
    <!-- Logo -->
    <div v-if="!isHome" class="logo">
      <b-link :to="{ name: 'home-dashboard' }">
        <b-img :src="require('@/assets/img/logos/visualvault.svg')" alt="Visual Vault" />
      </b-link>
    </div>

    <!-- Actions -->
    <div class="actions">
      <slot v-if="!isHome">
        <b-button @click="goToPanel()" variant="bordered" size="small">{{ $t('general.panel') }}</b-button>
        <b-button
          v-if="(user.profile.name === 'superadmin') || user.profile.permissions.includes('create_file_groups')"
          @click="openModal()" variant="primary" size="small">{{ $t('general.add') }}
        </b-button>

        <!-- Upload files -->
        <upload-files formType="creation" :fileInfo="{}" @closeModal="closeModal"></upload-files>
      </slot>

      <!-- Options -->
      <div v-if="user && user.active" class="opts">
        <!-- User options- -->
        <user-opts></user-opts>
      </div>

      <language-switcher />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './BaseHeader';
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    UserOpts: () => import('@/components/UserOpts/UserOpts.vue'),
    LanguageSwitcher: () => import('@/components/LanguageSwitcher/LanguageSwitcher.vue'),
    UploadFiles: () => import('@/components/Modals/Files/Upload/Upload.vue'),
  },

  data() {
    return {
      isHome: false,
      locales: Object.keys(this.$i18n.messages),
      mobileNavOpened: false,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  created() {
    this.checkHomePage();
  },

  mounted() {
    this.$root.$on('bv::dropdown::show', ({ componentId }) => {
      if (componentId === 'mobileNavDropdown') {
        this.mobileNavOpened = true;
      }
    });

    this.$root.$on('bv::dropdown::hide', () => {
      this.mobileNavOpened = false;
    });

    window.addEventListener('resize', this.resizeHandler);
  },

  methods: {
    resizeHandler() {
      this.mobileNavOpened = false;
      document.getElementsByTagName('body')[0].click();
    },

    checkHomePage() {
      this.isHome = this.$route.name === 'login';
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
    },

    openModal() {
      setTimeout(() => {
        this.$bvModal.show('upload-files');
      }, 50);
    },

    closeModal() {
      this.$bvModal.hide('upload-files');
    },

    goToPanel() {
      this.$router.push({ name: 'panel' }).catch(() => {});
    },

    logout() {
      this.$store.dispatch('auth/logout');
    },
  },

  watch: {
    $route() {
      this.checkHomePage();
      this.resizeHandler();
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
};
</script>
